@import "settings";

.container {
	@include clearfix;
}
.strong {
	font-weight:700;
}
h1,h2,h3,h4,h5,h6 {
	strong {
		font-family: $mulisemibold;
	}
}

p.big {
	font-size:26px;
}

body.privacy-policy {
	.text-section {
		p {
			font-size:16px;
		}
		ul {
			font-size:16px;
			margin-top: 0;
			li {
				margin:0 0 10px 0;
			}
		}
		a {
			color:$link-color;
		}
	}
}

.certification-homepage {
	padding:50px 0;
	text-align: center;
}

.scroll-up {
	position:fixed;
	bottom:0;
	right:0;
	z-index:10;
	width:45px;
	height:45px;
	background:$link-color;
	cursor:pointer;
	transition:all 0.3s;
	opacity:0;
	visibility: visible;
	&.active {
		opacity:1;
		visibility: visible;
	}
	&:hover {
		background:$font-color;
	}
	&:after {
		content:"";
		display:block;
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background:url(../images/arrow-white.png) no-repeat center center;
		background-size:auto 30%;
	}
}

.contact-trigger {
	position:fixed;
	top:50%;
	right:0;
	margin-top: -48px;
	width:95px;
	height:95px;
	background:$link-color;
	color:#fff;
	text-align: center;
	padding-top: 25px;
	transition:all 0.3s;
	cursor:pointer;
	z-index:3;
	&:hover {
		background:$font-color;
		color:#fff;
	}
}
.contact-sales {
	display:block;
	position:fixed;
	z-index:10;
	top:0;
	right:0;
	bottom:0;
	background:#f1f3f5;
	box-shadow: 0px 0px 35px 0px rgba(0,0,0,0.1);
	z-index:3;
	opacity:0;
	visibility: hidden;
	transition:all 0.3s;
	transform:translateX(100%);
	&.active {
		transform:translateX(0%);
		opacity: 1;
		visibility: visible;
	}
	.close {
		position:absolute;
		top:0;
		right:0;
		background:$link-color;
		width:40px;
		height:40px;
		cursor:pointer;
		transition:all 0.3s;
		&:hover {
			background:$font-color;
		}
		span {
			width:40px;
			height:40px;
			text-align: center;
			display:block;
			color:#fff;
			line-height:40px;
			font-size:24px;
			transform:rotate(45deg);
		}
	}
	.container {
		background:#fff;
		.padding {
			padding:20px 50px;
		}
		.contact-info {
			background:#f1f3f5;
			padding:20px 50px;
			font-size:14px;
			line-height:24px;
			a {
				color:$font-color;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}
		form {
			margin:20px 0 0 0;
			padding:0;
			input, textarea {
				display:block;
				border:none;
				border:1px solid #dddddd;
				width:100%;
				margin-bottom:10px;
				font-size:14px;
				font-family: 'Roboto', sans-serif;
				padding:10px 12px;
				background:#f1f3f5;
				&:focus {
					border-color:$font-color;
				}
			}
			textarea {
				max-width:100%;
				min-width:100%;
				min-height:150px;
				max-height:500px;
			}
			input[type="submit"] {
				background:none;
				text-transform: uppercase;
				border:none;
				background:$link-color;
				color:#fff;
				padding:10px 18px;
				cursor:pointer;
				transition:all 0.3s;
				&:hover {
					background:$font-color;
					color:#fff;
				}
			}
		}
	}
}

a.btn {
	display:inline-block;
	background:$link-color;
	color:#fff;
	padding:20px 30px;
	font-family: $muli;
	font-size:18px;
	position:relative;
	overflow: hidden;
	&:hover {
		color:$link-color;
		&:after {
			transform:rotate(15deg) translateX(0%);
		}
	}
	&:after {
		content:"";
		display:block;
		position:absolute;
		top:-60%;
		left:-20%;
		right:-20%;
		bottom:-60%;
		transform:rotate(15deg) translateX(-100%);
		background:#fff;
		transition:all 0.5s ease-in-out;
	}
	span {
		position:relative;
		z-index:2;
		transition:all 0.3s;
	}
	&.type2 {
		color:$link-color;
		background:transparent;
		border:2px solid $link-color;
		&:after {
			background:$link-color;
		}
		&:hover {
			color:#fff;
		}
	}
}

header {
	background:#fff;
	border-top:15px solid #f1f3f5;
	padding-bottom: 20px;
	p {
		margin:0;
	}
	.top-bar {
		@include clearfix;
		padding-top: 5px;
		.right {
			float:right;
			div {
				font-size:13px;
				color:#666666;
				display:inline-block;
				position:relative;
				padding-left: 20px;
				margin-left: 20px;
				a {
					color:#666666;
					&:hover {
						color:$link-color;
					}
				}
				&.phone:after {
					content:"";
					display:block;
					position:absolute;
					top:50%;
					left:0;
					width:10px;
					height:19px;
					transform:translateY(-50%);
					background:url(../images/phone.png);
				}
				&.email {
					padding-left: 25px;
					&:after {
						content:"";
						display:block;
						position:absolute;
						top:50%;
						left:0;
						width:18px;
						height:12px;
						transform:translateY(-50%);
						background:url(../images/email.png);
					}
				}
			}
		}
	}
	.main-bar {
		@include clearfix;
		a.logo {
			display:block;
			float:left;
			width:283px;
			height:117px;
			background:url(../images/logo.png);
			margin-top: -23px;
		}
		nav {
			float:right;
			padding-top: 25px;
			ul {
				margin:0;
				padding:0;
				li {
					display:inline-block;
					position:relative;
					overflow:hidden;
					margin:0 20px;
					&:last-child {
						margin-right: 0;
					}
					&:after {
						content:"";
						display:block;
						position:absolute;
						bottom:0;
						left:-100%;
						width:100%;
						height:2px;
						background:$link-color;
						transition:all 0.3s;
					}
					&:hover {
						&:after {
							left:0;
						}
						a {
							color:$link-color;
						}
					}
					a {
						color:$font-color;
						display:block;
						padding:13px 3px;
					}
				}
			}
		}
	}
}

.hero {
	background:no-repeat center center;
	background-size:cover;
	position:relative;
	&:after {
		content:"";
		display:block;
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background:rgba(0,0,0,0.5);
	}
	.content {
		padding:13rem 0 5rem 0;
		color:#fff;
		position:relative;
		z-index:2;
	}
	h1 {
		font-size:4.2rem;
		max-width:680px;
		line-height:1.2;
	}
	&.small-hero {
		.content {
			padding:60px 0;
		}
	}
}

.text-section {
	padding:7rem 0;
	font-size:20px;
}

.our-services {
	padding:8rem 0;
	.heading {
		@include clearfix;
		.left {
			float:left;
			width:40%;
			h2 {
				text-transform: uppercase;
				font-size:3rem;
				position:relative;
				display:inline-block;
				padding-right: 60px;
				&:after {
					content:"";
					display:block;
					position:absolute;
					top:50%;
					right:0;
					transform:translateY(-50%);
					width:32px;
					height:60px;
					background:url(../images/lines.png);
				}
			}
		}
		.right {
			float:left;
			width:60%;
		}
	}
	.services-carousel {
		margin:0 -15px;
		.slick-track {
			display:flex;
			flex-wrap:wrap;
		}
		.col30 {
			height:auto;
			margin:50px 0;
			padding:15px;
			font-size:14px;
			line-height:24px;
			color:#666;
			.inside {
				height:100%;
				box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
				transition:all 0.3s;
				padding:15px;
			}
			&:hover {
				.inside {
					box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
				}
				.image {
					h3 {
						color:$link-color;
					}
				}
			}
			a, span {
				display:block;
			}
			.image {
				height:0;
				padding-bottom: 60%;
				position:relative;
				.img-resize {
					position:absolute;
					top:0;
					left:0;
					right:0;
					bottom:0;
					overflow: hidden;
					img {
						max-width:none;
					}
				}
				h3 {
					position:absolute;
					bottom:15px;
					left:0;
					background:rgba(0,0,0,0.8);
					color:#fff;
					margin:0;
					padding:5px 10px;
					font-size:16px;
					transition:all 0.3s;
				}
			}
		}
	}
	.buttons {
		text-align: center;
	}
}

.slick-next, .slick-prev {
	width:43px;
	height:78px;
	left:-60px;
	&:before {
		display:none;
		text-indent:-9999999px;
	}
	background:url(../images/arrow-black.png) !important;
	&:hover {
		background:url(../images/arrow-red.png) !important;
	}
}
.slick-next {
	left:auto;
	right:-60px;
	transform:rotate(180deg) translate(0, 50%);
	transform-origin: center;
}

.home-gallery {
	padding:8rem 0;
	.heading {
		@include clearfix;
		.left {
			float:left;
			width:40%;
			h2 {
				text-transform: uppercase;
				font-size:3rem;
				position:relative;
				display:inline-block;
				padding-right: 60px;
				&:after {
					content:"";
					display:block;
					position:absolute;
					top:50%;
					right:0;
					transform:translateY(-50%);
					width:32px;
					height:60px;
					background:url(../images/lines.png);
				}
			}
		}
		.right {
			float:left;
			width:60%;
		}
	}
	.images {
		@include clearfix;
		margin:0 -20px;
		.image {
			float:left;
			width:25%;
			position:relative;
			height:0;
			padding-bottom: 25%;
			a {
				display:block;
				position:absolute;
				top:20px;
				left:20px;
				right:20px;
				bottom:20px;
				z-index:3;
				background:rgba(0,0,0,0.4);
				transition:all 0.3s;
				&:hover {
					background:rgba(0,0,0,0);
				}
			}
			&:before {
				content:"";
				display:block;
				position:absolute;
				top:20px;
				left:20px;
				right:20px;
				bottom:20px;
				z-index:2;
			}
			.img-resize {
				display:block;
				position:absolute;
				top:20px;
				left:20px;
				right:20px;
				bottom:20px;
				overflow: hidden;
				img {
					max-width:none;
				}
			}
			.content {
				position:absolute;
				bottom:20px;
				left:20px;
				right:20px;
				padding:20px;
				color:#fff;
				z-index:3;
			}
			&:first-child {
				width:50%;
				padding-bottom: 50%;
			}
			&:last-child {
				width:50%;
			}
		}
	}
	.buttons {
		text-align: center;
		margin-top: 50px;
	}
}

.certification {
	padding:8rem 0;
	.heading {
		@include clearfix;
		.left {
			float:left;
			width:40%;
			h2 {
				text-transform: uppercase;
				font-size:3rem;
				position:relative;
				display:inline-block;
				padding-right: 60px;
				&:after {
					content:"";
					display:block;
					position:absolute;
					top:50%;
					right:0;
					transform:translateY(-50%);
					width:32px;
					height:60px;
					background:url(../images/lines.png);
				}
			}
		}
		.right {
			float:left;
			width:60%;
		}
	}
	.logos {
		text-align: center;
		padding:50px 0;
		img {
			display:inline-block;
			vertical-align: middle;
		}
	}
	.buttons {
		text-align: center;
		padding-top: 50px;
	}
}

footer {
	background:#c9c9c9;
	font-size:15px;
	color:#393939;
	line-height:25px;
	a {
		color:#393939;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	.top {
		@include clearfix;
	}
	.col30 {
		float:left;
		width:33.333%;
		padding-right: 50px;
		h3 {
			font-size:24px;
		}
	}
	ul {
		margin:0;
		padding:0;
		li {
			position:relative;
			padding-left: 15px;
			margin:5px 0;
			&:before {
				content:"";
				display:block;
				position:absolute;
				left:0;
				top:50%;
				width:6px;
				height:6px;
				background:$link-color;
				transform:translateY(-50%);
			}
			a {
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	.bottom {
		border-top: 2px solid #464646;
		padding:25px 0;
		font-size:14px;
		@include clearfix;
		.left {
			float:left;
			margin-top: 6px;
		}
		.right {
			float:right;
			.social {
				float:left;
				display:inline-block;
				width:40px;
				height:40px;
				background:no-repeat center center;
				transition:all 0.3s;
				margin-left:15px;
				&:hover {
					opacity:0.6;
				}
				&.tw {
					background:url(../images/twitter.png);
				}
				&.fb {
					background:url(../images/facebook.png);
				}
				&.ln{
					background:url(../images/linkedin.png);
				}
			}
		}
	}
}

.services-overview {
	padding:8rem 0;
	h2 {
		font-size:3rem;
		margin-top: 0;
		color:$link-color;
	}
	.services-list {
		@include clearfix;
		ul {
			margin:0;
			padding:0;
			float:left;
			width:33.3333%;
			list-style:none;
			font-family: $muli;
			font-size:20px;
			padding-right:60px;
			li {
				border-bottom: 1px solid #e5e5e5;
				padding:20px 0;
				a {
					color:$font-color;
					&:hover {
						display:block;
						color:$link-color;
					}
				}
			}
		}
	}
}

.services-menu {
	position:relative;
	&:before {
		content:"";
		position:absolute;
		display:block;
		top:0;
		left:0;
		width:50%;
		bottom:0;
		background:$link-color;
	}
	&:after {
		content:"";
		position:absolute;
		display:block;
		top:0;
		right:0;
		width:50%;
		bottom:0;
		background:#f1f3f5;
	}
	.container {
		background:#f1f3f5;
		position:relative;
		z-index:2;
		display:flex;
		flex-wrap:wrap;
		.left {
			background:$link-color;
			padding:40px 40px 40px 0;
			min-width:170px;
			text-align: center;
			color:#fff;
			font-size:20px;
			display:flex;
			h2 {
				  align-self: center;
			}
		}
		.menu-items {
			padding:40px 0 40px 50px;
			ul {
				float:left;
				width:33.3333%;
				list-style:none;
				font-size:14px;
				li {
					margin:12px 0;
					a {
						color:$font-color;
						transition:none;
						&:hover {
							color:$link-color;
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}

.product-details-heading {
	padding:50px 0;
	h1 {
		margin-top: 0;
		font-size:4rem;
	}
}

.product-details-image {
	.image {
		height:0;
		padding-bottom: 30%;
		position:relative;
		.img-resize {
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			overflow: hidden;
			img {
				max-width:none;
			}
		}
	}
	.product-details-image-info {
		background:#f1f3f5;
		@include clearfix;
		padding:20px 15px;
		h3 {
			float:left;
			margin:0;
			padding:0;
		}
		.logo {
			float:right;
		}
	}
}

.product-details-lists {
	padding:40px 0;
	.list {
		float:left;
		width:50%;
		padding-right: 30px;
		&:nth-child(2n+1) {
			clear:left;
		}
		ul {
			list-style:none;
			margin:0;
			padding:0;
			font-size:15px;
			li {
				margin:15px 0;
			}
		}
	}
}

.product-details-gallery {
	background:#f1f3f5;
	padding:50px 0;
	h3 {
		margin:0;
		padding:0;
		font-size:3rem;
	}
	.gallery {
		@include clearfix;
		margin-top: 40px;
		.image {
			width:130px;
			height:130px;
			float:left;
			margin:0 20px 20px 0;
			position:relative;
			.img-resize {
				display:block;
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				transition:all 0.3s;
				overflow: hidden;
				&:hover {
					opacity:0.7;
				}
				img {
					max-width:none;
				}
			}
		}
	}
}


.certification-subpage {
	padding:60px 0;
	.container {
		.heading {
			@include clearfix;
			.left {
				float:left;
				width:40%;
				h2 {
					text-transform: uppercase;
					font-size:3rem;
					position:relative;
					display:inline-block;
					padding-right: 60px;
					&:after {
						content:"";
						display:block;
						position:absolute;
						top:50%;
						right:0;
						transform:translateY(-50%);
						width:32px;
						height:60px;
						background:url(../images/lines.png);
					}
				}
			}
			.right {
				float:left;
				width:60%;
			}
		}
		.certification-grid {
			display:flex;
			flex-wrap:wrap;
			.col25 {
				float:left;
				width:25%;
				border-right:1px solid #c1c1c1;
				margin:50px 0;
				text-align: center;
				padding:0 20px 50px 20px;
				position:relative;
				&:nth-child(4n) {
					border-right: none;
				}
				.image {
					height:104px;
					text-align: center;
					display:flex;
					align-items: center;
					justify-content: center;
					img {
						width:auto;
						height:auto;
					}
				}
				p {
					font-size:14px;
				}
				a.pdf {
					position:absolute;
					bottom:0;
					left:50%;
					transform:translateX(-50%);
					display:inline-block;
					color:#fff;
					background:$link-color;
					font-size:12px;
					padding:8px 15px;
					transition:all 0.3s;
					&:hover {
						background:$font-color;
					}
				}
			}
		}
	}
}

.media-gallery-subpage {
	padding:50px 0;
	.container {
		.heading {
			@include clearfix;
			.left {
				float:left;
				width:40%;
				h2 {
					text-transform: uppercase;
					font-size:3rem;
					position:relative;
					display:inline-block;
					padding-right: 60px;
					&:after {
						content:"";
						display:block;
						position:absolute;
						top:50%;
						right:0;
						transform:translateY(-50%);
						width:32px;
						height:60px;
						background:url(../images/lines.png);
					}
				}
			}
		}
		.media-grid {
			@include clearfix;
			.container {
				margin:0 -20px;
			}
			.col25 {
				padding:20px;
				float:left;
				width:33.3333%;
				a:hover {
					.image {
						border:0px solid #fff;
					}
					h3 {
						color:$link-color;
					}
				}
				.inside {
					height:0;
					padding-bottom: 60%;
					position:relative;
					span {
						display:block;
					}
					.image {
						border:15px solid #fff;
						transition:all 0.3s;
					}
					.image, .img-resize {
						position:absolute;
						top:0;
						left:0;
						right:0;
						bottom:0;
						overflow: hidden;
					}
					img {
						max-width:none;
					}
					h3 {
						position:absolute;
						bottom:15px;
						left:0;
						background:rgba(0,0,0,0.8);
						color:#fff;
						margin:0;
						padding:5px 10px;
						font-size:16px;
						transition:all 0.3s;
					}
				}
			}
		}
	}
}

.contact-details {
	padding:40px 0;
	h2 {
		margin-top: 0;
	}
	.container {
		@include clearfix;
		.col30 {
			float:left;
			width:33.3333%;
			padding-right: 40px;
			line-height:36px;
		}
	}
}

.map-form {
	display:flex;
	flex-wrap:wrap;
	border-bottom: 5px solid #464646;
	.left {
		float:left;
		width:50%;
		position:relative;
		iframe {
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			width:100%;
			height:100%;
		}
	}
	.right {
		float:left;
		width:50%;
		max-width:570px;
		padding:40px 0 40px 30px;
		h3 {
			font-size:26px;
		}
		form {
			margin:20px 0 0 0;
			padding:0;
			input, textarea {
				display:block;
				border:none;
				border:1px solid #dddddd;
				width:100%;
				margin-bottom:30px;
				font-size:14px;
				font-family: 'Roboto', sans-serif;
				padding:18px 12px;
				&:focus {
					border-color:$font-color;
				}
			}
			textarea {
				max-width:100%;
				min-width:100%;
				min-height:200px;
				max-height:500px;
			}
			input[type="submit"] {
				background:none;
				text-transform: uppercase;
				border:1px solid $font-color;
				padding:18px;
				cursor:pointer;
				transition:all 0.3s;
				&:hover {
					background:$font-color;
					color:#fff;
				}
			}
		}
	}
}

.two-column-image {
	position:relative;
	@include clearfix;
	.left {
		width:50%;
		position:absolute;
		top:0;
		left:0;
		bottom:0;
		background:#ccc no-repeat center center;
		background-size:cover;
	}
	.container {
		float:right;
		width:50%;
		padding:10rem 0 10rem 10rem;
	}
	h3 {
		font-size:26px;
		position:relative;
		&:after {
			content:"";
			display:block;
			position:absolute;
			top:-20px;
			left:-20px;
			height:3px;
			width:50px;
			background:$link-color;
		}
	}
}

.our-facilities {
	padding:50px 0;
	.container {
		.heading {
			@include clearfix;
			.left {
				float:left;
				width:40%;
				h2 {
					text-transform: uppercase;
					font-size:3rem;
					position:relative;
					display:inline-block;
					padding-right: 60px;
					&:after {
						content:"";
						display:block;
						position:absolute;
						top:50%;
						right:0;
						transform:translateY(-50%);
						width:32px;
						height:60px;
						background:url(../images/lines.png);
					}
				}
			}
			.right {
				float:left;
				width:60%;
			}
		}
		.facilities-grid {
			padding-top: 20px;
			@include clearfix;
			margin:0 -24px;
			.col30 {
				float:left;
				width:33.33333%;
				padding:24px;
				.inside {
					background-color:#f1f3f5;
					padding:0 20px 20px 20px;
					font-size:13px;
					line-height:25px;
				}
				h3 {
					color:$link-color;
					font-size:24px;
					border-bottom:2px solid $font-color;
					padding:20px;
					margin:0 -20px;
				}
			}
		}
	}
}


@media only screen and (max-width:1200px) {
	header {
		.main-bar {
			nav {
				ul {
					li {
						margin:0 6px;
					}
				}
			}
		}
	}
	.services-menu {
		.container {
			.left {
				min-width:0;
				width:30%;
			}
			.menu-items {
				width:70%;
			}
		}
	}
	.map-form {
		.right {
			padding-right: 40px;
		}
	}
}

@media only screen and (max-width:1000px) {
	html {
		font-size:45%;
	}
	header {
		.main-bar {
			position:relative;
			.responsive-nav {
				display:block;
			}
			nav {
				position:absolute;
				background:#fff;
				top:113px;
				left:-100px;
				right:-100px;
				z-index:4;
				transition:all 0.3s;
				opacity:0;
				visibility: hidden;
				ul {
					padding:0 100px 30px 100px;
					li {
						display:block;
						border-bottom:1px solid #ccc;
						a {

						}
					}
				}
				&.active {
					opacity:1;
					visibility: visible;
				}
			}
		}
	}
	p.big {
		font-size:22px;
	}
	.text-section {
		font-size:16px;
	}
	.home-gallery {
		.images {
			margin:0 -5px;
			.image {
				width:50% !important;
				padding-bottom: 50% !important;
				a, .img-resize {
					top:5px;
					left:5px;
					right:5px;
					bottom:5px;
				}
			}
		}
	}
	footer {
		.bottom {
			.left {
				width:100%;
			}
			.right {
				margin-top: 10px;
				float:left;
				a:first-child {
					margin-left: 0;
				}
			}
		}
	}
	.services-overview {
		.services-list {
			ul {
				font-size:16px;
			}
		}
	}
	.services-menu {
		&:before {
			background:#f1f3f5;
		}
		.container {
			display:block;
			.left {
				float:none;
				width:auto;
				padding:20px 0;
				margin:0 -100px;
				padding:20px 100px;
				font-size:16px;
				h2 {
					margin:0;
				}
			}
			.menu-items {
				width:100%;
				padding:40px 0;
				ul {
					&:first-child {
						padding-left: 0;
					}
				}
			}
		}
	}
	.contact-details {
		.container {
			.col30 {
				width:50%;
				margin-bottom: 15px;
			}
		}
	}
	.our-facilities {
		.container {
			.facilities-grid {
				.col30 {
					width:50%;
				}
			}
		}
	}
	.certification-subpage {
		.container {
			.certification-grid {
				.col25 {
					width:33.333%;
					&:nth-child(4n) {
						border-right: 1px solid #c1c1c1;
					}
					&:nth-child(3n) {
						border-right: none;
					}
				}
			}
		}
	}
	.media-gallery-subpage {
		.container {
			.media-grid {
				.col25 {
					width:50%;
				}
			}
		}
	}
}

@media only screen and (max-width:800px) {
	.heading {
		.right, .left {
			width:100% !important;
			float:left !important;
		}
	}
	footer {
		.col30 {
			width:50%;
		}
		.col30:first-child {
			width:100%;
			padding-right: 0;
		}
	}
	.services-overview {
		.services-list {
			ul {
				width:50%;
			}
		}
	}
	.map-form {
		.left {
			width:100%;
			height:500px;
		}
		.right {
			width:100%;
			max-width:none;
			padding:40px;
		}
	}
	.contact-details {
		.container {
			.col30 {
				width:100%;
				margin-bottom: 15px;
			}
		}
	}
	.two-column-image {
		.left {
			position:static;
			width:100%;
			height:0;
			padding-bottom: 50%;
		}
		.container {
			padding:50px 0;
			width:100%;
		}
	}
	.certification-subpage {
		.container {
			.certification-grid {
				.col25 {
					margin:20px 0;
					width:50%;
					&:nth-child(3n) {
						border-right: 1px solid #c1c1c1;
					}
					&:nth-child(2n) {
						border-right: none;
					}
				}
			}
		}
	}
	.media-gallery-subpage {
		.container {
			.media-grid {
				margin:0 -10px;
				.col25 {
					padding:10px;
					width:50%;
					.inside {
						padding-bottom: 80%;
						h3 {
							font-size:12px;
						}
						.image {
							border:5px solid #fff;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width:600px) {
	.pageAlign {
		padding:0 15px;
	}
	header {
		border-top: 30px solid #f1f3f5;
		.top-bar {
			margin-top: -33px;
			.right {
				div {
					margin-left: 3px;
					font-size:11px;
				}
			}
		}
		.main-bar {
			margin-top: 15px;
			a.logo {
				width:177px;
				height:73px;
				background-size:contain;
				margin-top: 0;
			}
			.responsive-nav {
				margin-top: 22px;
			}
			nav {
				top:92px;
				left:-15px;
				right:-15px;
				ul {
					padding:0 15px 30px 15px;
				}
			}
		}
	}
	.slick-next,.slick-prev {
		display:none !important;
	}
	.home-gallery {
		.images {
			.image {
				.content {
					bottom:5px;
					left:5px;
					right:5px;
					font-size:12px;
				}
			}
		}
	}
	footer {
		.col30 {
			width:100%;
			padding-right: 0;
		}
	}
	.services-overview {
		.services-list {
			ul {
				font-size:12px;
				width:100%;
				li {
					padding:10px 0;
				}
			}
		}
	}
	.services-menu {
		.container {
			.left {
				margin:0 -15px;
			}
			.menu-items {
				ul {
					margin:0;
					width:50%;
					padding:0 20px 0 0;
					&:last-child {
						clear:left;
					}
				}
			}
		}
	}
	.product-details-lists {
		.list {
			width:100%;
			padding-right: 0;
		}
	}
	.product-details-image {
		.product-details-image-info {
			.logo {
				margin-top: 5px;
				float:left;
				clear:left;
			}
		}
	}
	.product-details-gallery {
		.gallery {
			.image {
				width:80px;
				height:80px;
			}
		}
	}
	.map-form {
		.left {
			height:300px;
		}
		.right {
			padding:30px 15px;
			input,textarea {
				margin-bottom: 10px;
			}
		}
	}
	.our-facilities {
		.container {
			.facilities-grid {
				margin:0;
				.col30 {
					padding:12px 0;
					width:100%;
				}
			}
		}
	}
	.contact-trigger {
		top:auto;
		bottom:0;
		margin-top: 0;
		left:0;
		width:auto;
		height:auto;
		padding:12px 0;
	}
	.contact-sales {
		.container {
			.padding {
				padding: 20px 15px;
			}
			.contact-info {
				padding: 20px 15px;
			}
		}
	}
	.scroll-up {
		background:$font-color;
		&:hover {
			background:$font-color;
		}
	}
}

@media only screen and (max-height:650px) {
	.contact-sales {
		overflow-y: scroll;
	}
}

/*wp additional css*/
.our-facilities .container .facilities-grid {
	display:flex;
	flex-wrap:wrap;
}
.custom-row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -10px;
	margin-left: -10px;
}
.col-4, .col-8 {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 10px;
	padding-left: 10px;
}
.col-4 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}
.col-8 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}
.hero {
	.services {
		.col-4 {
			a.youtube-block {
				display:block;
				position:relative;
				&:hover {
					&:after {
						transform:translateY(-50%) translateX(-50%) scale(1.2);
					}
				}
				&:after {
					content:"";
					display:block;
					position:absolute;
					top:50%;
					left:50%;
					width:50px;
					height:50px;
					background:url(../images/video-play.svg) no-repeat center center;
					background-size:contain;
					transform:translateY(-50%) translateX(-50%);
					transition:all 0.3s;
				}
			}
			.service {
				background:no-repeat center center;
				background-size:cover;
			}
		}
	}
}
@media screen and (max-width: 991px) {
	.col-4, .col-8 {
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
	}
	.hero .content.services {
			padding-top: 0;
	}
	.hero .services .col-4 {
			-ms-flex: 0 0 50%;
			flex: 0 0 50%;
			max-width: 50%;
	}
	.content.col-4 {
			padding: 13rem 15px 30px 15px;
	}
}
.custom-row h1 {
	font-size: 36px;
	font-weight: 400;
	line-height: 44px;
}
@media screen and (min-width: 992px){
	.custom-row h1 {
		padding-right: 40px
	}
	.custom-row .services {
		padding-left: 40px;
	}
}
.custom-row .services {

}
.custom-row .services .service {
	height: 120px;
	margin-bottom: 20px;
	position: relative;
}

.custom-row .services .service .title {
	font-size: 18px;
	line-height: 22px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	color: #FFF;
	display: none;
}
.custom-row .services a:hover .title {
	display: block;
}
.custom-row .services a:hover .service:before {
	content: '';
	background-color: rgba(238, 13, 8,0.3);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.hero.small-hero .content {
    padding: 100px 0;
}
