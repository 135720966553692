@import "mixin";



$base-font-size: 16px; /*DEFINE WEBSITE COLORS*/
$link-color: #ee0d08; //change this

$font-color:#000;

/*DEFINE CUSTOM FONTS (remove if not needed)*/
@font-face {
    font-family: 'muliregular';
    src: url('../fonts/muli-webfont.eot');
    src: url('../fonts/muli-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/muli-webfont.woff2') format('woff2'),
         url('../fonts/muli-webfont.woff') format('woff'),
         url('../fonts/muli-webfont.ttf') format('truetype'),
         url('../fonts/muli-webfont.svg#muliregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'mulisemibold';
    src: url('../fonts/muli-semibold-webfont.eot');
    src: url('../fonts/muli-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/muli-semibold-webfont.woff2') format('woff2'),
         url('../fonts/muli-semibold-webfont.woff') format('woff'),
         url('../fonts/muli-semibold-webfont.ttf') format('truetype'),
         url('../fonts/muli-semibold-webfont.svg#mulisemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

$muli: 'muliregular';
$mulisemibold: 'mulisemibold';

/*START TEMPLATE STYLES*/
a,
img {
	border: none;
	text-decoration: none;
	outline: 0;
}

img {
	display: block;
	max-width: 100%;
}

*:focus {
	outline: 0;
}

* {
	@include box-sizing;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $muli;
}
// Remove if we DON'T use Google fonts
footer ul,
header ul {
	list-style: none;
}

fieldset {
	border: 0;
}

input {
	padding: 0;
	margin: 0;
}

a {
	color: $link-color;
	@include color-transition;

	&:hover {
		color: $font-color;
	}
}

.pageAlign {
	margin: 0 auto;
	max-width: 1420px;
	padding: 0 100px;
}

.pageAlignFluid {
	margin: 0 auto;
	max-width: 2020px;
	padding: 0 50px;
}

.relative {
	position: relative;
}

.fullWidth {
	float: left;
	width: 100%;
}

.invisible {
	display: none;
}

.fl {
	float: left;
}

.fr {
	float: right;
}

html {
	font-size: 62.5%;
	height: 100%;
}

body,
input,
select,
textarea {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	/*REMOVE if we DON'T use Google fonts*/
}

body {
	width: 100%;
	padding: 0;
	margin: 0;
	min-height: 100%;
	height: 100%;
	min-width: 100%;
	color: $font-color;
	font-size: $base-font-size;
	background: #fff;
}

p {
	margin-bottom: 3rem;
}

::selection {
	background: $link-color;
	/* WebKit/Blink Browsers */
	color: #fff;
}

::-moz-selection {
	background: $link-color;
	/* Gecko Browsers */
	color: #fff;
}

.responsive-nav {
	display: none;
	float:right;
	margin-top:35px;
	color: $link-color;
	cursor: pointer;
	@include border-radius-value(3px);
	@include all-transition;
	button {
		padding: 7px 10px;
		background: none;
		border: none;
		position: relative;
		height: 40px;
		width: 51px;
		cursor: pointer;
		outline: none;
		@include all-transition;
		float: right;
		&:hover {
			.line {
				background: $link-color;
			}
		}
	}
}

.line {
	position: absolute;
	height: 3px;
	width: 30px;
	background: $link-color;
	left: 10px;
	@include all-transition;
}

.line-t {
	top: 9px;
}

.line-m {
	top: 19px;
}

.line-b {
	top: 29px;
}

.menu-on .line-m,
.menu-on:hover .line-m {
	background: transparent;
	opacity:0;
}

.menu-on .line {
	top: 18px;
}

.menu-on .line-t {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.menu-on .line-b {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
